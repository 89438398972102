import React from "react";
import ImageList from "../components/ImageList";

function Dashboard() {
  return (
    <div>
      <ImageList />
    </div>
  );
}

export default Dashboard;
